<template>
    <div class="planyo-event-detail">
        <div v-if="photo" class="event-image">
            <div :style="{ 'background-image': 'url(' + photo +')' }" class="d-none d-sm-block"></div>
            <img v-bind:src="photo" class="img-fluid d-block d-sm-none" />
        </div>
        <div class="event-detail-padding">
            <div class="event-detail-content">
                <div class="event-detail-title">
                    <p>Teacher:<br><strong>{{ teacherName }}</strong></p>
                    <p>Lesson type:<br><strong>
                        <template>
                            {{ lessonType }}
                        </template>
                    </strong></p>
                    <p>Location:<br><strong>{{ location }}</strong></p>
                    <p v-if="!isPrivateFoundationLesson">Date and time:<br><strong>{{ startTime }}</strong></p>
                    <p v-if="!isPrivateFoundationLesson">Duration:<br><strong>{{ durationReadable }}</strong></p>
                    <p v-if="isPrivateFoundationLesson">Number of lessons<br><strong>6 Lessons</strong></p>
                    <p v-if="isPrivateFoundationLesson">Duration per lesson:<br><strong>{{ durationReadable }}</strong></p>
                </div>
                <div class="event-detail-description">
                    <p v-if="description" v-html="description"></p>

                    <template v-if="!isPrivateFoundationLesson">
                        <a :href="link" class="btn btn-primary">Book Lesson</a>
                    </template>
                    <template v-else>
                        <a :href="link" class="btn btn-primary">Purchase Course</a>

                        <span class="private-foundation-course-info">* Please note this step does not create a booking for your first lesson. After purchasing the course, you will be prompted to book your first lesson from your dashboard.</span>
                    </template>
                </div>
            </div>
        </div>

        <modal v-if="confirmBookNow == true">
            <h3 slot="header">Are you sure you'd like to book {{ title }} on {{ startTime }}?</h3>
            <div slot="body">
                <p v-if="bookingStatus == 'not started'">
                    You currently have {{ lessonsLeft }} lessons to book for this lesson
                </p>
                <p v-else-if="bookingStatus == 'processing'">
                    Booking your lesson now...
                </p>
                <p v-else-if="bookingStatus == 'error'" class="text-danger">
                    {{ bookingStatusMessage }}
                </p>
                <div v-else-if="bookingStatus == 'complete'" v-html="bookingStatusMessage">
                </div>
            </div>
            <div slot="footer">
                <button type="button" class="btn btn-warning" @click="confirmBookNow = false">Cancel</button>
                <button type="button" class="btn btn-primary" :class="{ 'disabled': processingBooking }" :disabled="processingBooking" @click="completeBooking">Complete Booking</button>
            </div>
        </modal>
    </div>
</template>

<script>
    import moment from 'moment';
    import Modal from './Modal';

    export default {
        data() {
            return {
                processingBooking: false,
                confirmBookNow: false,
                bookingStatus: 'not started',
                bookingStatusMessage: '',
            };
        },
        components: {
            Modal,
        },
        store: {
            resources: 'resources',
            lesson: 'calendar.lesson',
            selectedTeacherLocation: 'selectedTeacherLocation',
            numPeople: 'calendar.numPeople',
            // foundationsCourse: 'foundationsCourse',
        },
        props: {
            event: { required: true},
        },
        watch: {
            confirmBookNow: function(value) {
                this.processingBooking = false;
                this.bookingStatus = 'not started';
                this.bookingStatusMessage = '';
            },
        },
        methods: {
        },
        computed: {
            isPrivateFoundationLesson() {
                if (typeof this.$store.calendar.lesson == 'undefined') {
                    return false;
                }
                return (this.$store.calendar.lesson == 'Private Foundations Course') ? true : false;
            },
            hasNumberOfPersons() {
                if (typeof this.$store.calendar.lesson == 'undefined') {
                    return false;
                }
                return (this.$store.calendar.lesson == 'Single Lesson' || this.$store.calendar.lesson == 'Private Follow-up') ? true : false;
            },
            duration() {
                if (this.event && this.event.duration) {
                    return parseFloat(this.event.duration) / 60;
                }
                return 0.75;
            },
            durationReadable() {
                let duration = this.event.duration || 45;
                duration = parseFloat(duration);
                if (duration < 60) {
                    return duration + ' minutes';
                }

                const timeInHours = duration / 60;
                const hours = Math.floor(timeInHours);
                const minutes = parseInt((timeInHours - hours) * 60);
                let timeString = '';
                if (hours == 1) {
                    timeString += '1 hour';
                } else {
                    timeString += hours + ' hours';
                }

                if (minutes == 0) {
                    return timeString;
                }
                if (minutes == 1) {
                    timeString += ' 1 minute ';
                } else {
                    timeString += ' ' + minutes + ' minutes ';
                }

                return timeString;
            },
            link() {
                if (this.selectedTeacherLocation && this.event && this.event.planyo_id) {
                    let timeAsDecimal = 0;
                    timeAsDecimal = this.startTimeMoment.hours() + (this.startTimeMoment.minutes() / 60);
                    if (this.isPrivateFoundationLesson) {
                        // return '/booking?feedback_url=https%3A%2F%2Fgokhalemethod.com%2Fbooking&mode=reserve&planyo_lang=en&prefill=true&resource_id='+this.foundationsCourse+'&one_date=' + encodeURIComponent(this.startTimeMoment.format('DD MMM YYYY')) + '&start_time=' + timeAsDecimal + '&rental_time_value=' + this.duration + '&rental_prop_persons=' + this.numPeople + '&rental_prop_Teacher_ID=' + this.$store.teacher;
                        return '/booking?feedback_url=https%3A%2F%2Fgokhalemethod.com%2Fbooking&mode=reserve&planyo_lang=en&prefill=true&resource_id='+encodeURIComponent(this.selectedTeacherLocation)+'&one_date=' + encodeURIComponent(this.startTimeMoment.format('DD MMM YYYY')) + '&start_time=' + timeAsDecimal + '&rental_time_value=' + this.duration + '&rental_prop_persons=' + this.numPeople + '&rental_prop_Teacher=' + encodeURIComponent(this.teacherName) + '&rental_prop_Teacher_ID=' + encodeURIComponent(this.selectedTeacherLocation);
                    }
                    if (this.hasNumberOfPersons) {
                        return '/booking?feedback_url=https%3A%2F%2Fgokhalemethod.com%2Fbooking&mode=reserve&planyo_lang=en&prefill=true&resource_id=' + encodeURIComponent(this.selectedTeacherLocation) + '&one_date=' + encodeURIComponent(this.startTimeMoment.format('DD MMM YYYY')) + '&start_time=' + timeAsDecimal + '&rental_time_value=' + this.duration + '&rental_prop_persons=' + this.numPeople + '&rental_prop_Teacher=' + encodeURIComponent(this.teacherName) + '&rental_prop_Teacher_ID=' + encodeURIComponent(this.selectedTeacherLocation);
                    }
                    return '/booking?feedback_url=https%3A%2F%2Fgokhalemethod.com%2Fbooking&mode=reserve&planyo_lang=en&prefill=true&resource_id=' + encodeURIComponent(this.selectedTeacherLocation) + '&one_date=' + encodeURIComponent(this.startTimeMoment.format('DD MMM YYYY')) + '&start_time=' + timeAsDecimal + '&rental_prop_Teacher=' + encodeURIComponent(this.teacherName) + '&rental_prop_Teacher_ID=' + encodeURIComponent(this.selectedTeacherLocation);
                }
                return '/booking';
            },
            startTimeMoment() {
                if (this.event && this.event.start_time) {
                    return moment(this.event.start_time.date);
                }
                return moment();
            },
            startTime() {
                return this.startTimeMoment.format("ddd, MMM Do YYYY, HH:mm");
            },
            teacherName() {
                if (typeof this.resources[this.selectedTeacherLocation] !== 'undefined') {
                    return this.resources[this.selectedTeacherLocation].teacher_name;
                }
                return '-';
            },
            lessonType() {
                return this.$store.calendar.lesson;
            },
            location() {
                return this.$store.calendar.location;
            },
            // title() {
            //     if (typeof this.resources[this.selectedTeacherLocation] !== 'undefined') {
            //         return this.resources[this.selectedTeacherLocation].name;
            //     }
            //     return 'Event';
            // },
            photo() {
                if (typeof this.resources[this.selectedTeacherLocation] !== 'undefined' && this.resources[this.selectedTeacherLocation].photo !== '') {
                    return this.resources[this.selectedTeacherLocation].photo;
                }
                return false;
            },
            description() {
                if (typeof this.resources[this.selectedTeacherLocation] !== 'undefined') {
                    return this.resources[this.selectedTeacherLocation].prop_description;
                }
                return false;
            },
            // url() {
            //     if (typeof this.resources[this.event.planyo_id] !== 'undefined' && typeof this.resources[this.event.planyo_id].url !== 'undefined' && this.resources[this.event.planyo_id].url !== '' && this.resources[this.event.planyo_id].url !== null) {
            //         return this.resources[this.event.planyo_id].url;
            //     }
            //     return false;
            // },
        },
    }
</script>

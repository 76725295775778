<template>
<div class="form-group gokhale-cal__inputs">
    <label :for="thisId">How many people will be attending?</label>
    <select :id="thisId" v-model="numPeople" class="form-control">
        <option v-for="(l, index) in allowedNumberOfPersons" :key="index" :value="l.val">{{ l.label }}</option>
    </select>
</div>
</template>

<script>
export default {
    store: {
        numPeople() {
            return 'calendar.numPeople';
        },
    },
    computed: {
        allowedNumberOfPersons() {
            if (typeof this.$store.calendar.lesson == 'undefined') {
                return [
                    {val: 1, label: '1 Person'},
                ];
            }

            if (this.$store.calendar.lesson == 'Private Foundations Course') {
                if (this.$store.calendar.maxPersons) {
                    let output = [
                        {val: 1, label: '1 Person'}
                    ];
                    for (let index = 2; index <= this.$store.calendar.maxPersons; index++) {
                        output.push({
                            val: index, label: index + ' People'
                        });
                    }
                    return output;
                }
                return [
                    {val: 1, label: '1 Person'},
                    {val: 2, label: '2 People'},
                    {val: 3, label: '3 People'},
                    {val: 4, label: '4 People'},
                    {val: 5, label: '5 People'},
                    {val: 6, label: '6 People'},
                    {val: 7, label: '7 People'},
                    {val: 8, label: '8 People'},
                ];
            }
            if (this.$store.calendar.lesson == 'Single Lesson' || this.$store.calendar.lesson == 'Private Follow-up') {
                return [
                    {val: 1, label: '1 Person'},
                    {val: 2, label: '2 People'},
                    {val: 3, label: '3 People'},
                ];
            }
            return [
                {val: 1, label: '1 Person'},
            ];
        },
        thisId() {
            return 'select_num_people_' + this._uid;
        }
    },
}
</script>
<template>
    <div>
        <div v-if="noEvents" class="planyo-event-detail">
            <div class="event-image">
                <div :style="{ 'background': '#CACACA' }"></div>
            </div>
            <div class="event-detail-padding">
                <div class="event-detail-content">
                    <div class="event-detail-title">
                        <h2 v-html="reasonForNoEvents"></h2>
                    </div>
                    <div class="event-detail-description">
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-else v-for="event in events" :key="event.id"> -->
            <event-detail v-if="!noEvents && selectedEvent" :event="selectedEvent"></event-detail>
        <!-- </div> -->
    </div>
</template>

<script>
    export default {
        store: {
            // resources: 'resources',
            selectedEvent() {
                return 'calendar.selectedEvent';
            },
            events() {
                return 'calendar.events';
            },
        },
        computed: {
            haveEventsThisMonth() {
                return (this.$store.calendar.events.length > 0) ? true : false;
            },
            noEvents() {
                if (this.haveEventsThisMonth) {
                    if (this.$store.calendar.todaysEvents.length == 0) {
                        return true;
                    }
                    return false;
                }
                return true;
            },
            reasonForNoEvents() {
                if (this.$store.calendar.lesson == '' || this.$store.calendar.location == '') {
                    if (this.$store.calendar.lesson == '' && this.$store.calendar.location == '') {
                        return 'Please select a lesson and location to view upcoming availabilty';
                    } else if (this.$store.calendar.lesson == '') {
                        return 'Please select a lesson to view upcoming availabilty';
                    }
                    return 'Please select a location to view upcoming availabilty';
                }
                if (this.$store.calendar.selectedDay === false && this.$store.calendar.todaysEvents.length == 0) {
                    return 'Please select a day of the month to view upcoming availability';
                }
                if (this.haveEventsThisMonth && this.$store.calendar.todaysEvents.length == 0) {
                    return 'There are no lessons scheduled on this day, please select a different day';
                }
                if (!this.haveEventsThisMonth) {
                    return 'There are no lessons scheduled this month, please check other months';
                }
            },
        },
        methods: {
            nextMonth() {
                this.$store.calendar.nextMonth();
            }
        },

    }
</script>

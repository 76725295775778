<template>
<div class="form-group gokhale-cal__inputs" v-if="this.lessons.length > 1">
    <label :for="thisId">Select a lesson</label>
    <select :id="thisId" v-model="lessonSelection" class="form-control">
        <!-- <option value="">Select a lesson</option> -->
        <option v-for="(lessonName, index) in lessons" :key="index" :value="lessonName">{{ lessonName }}</option>
    </select>
</div>
</template>

<script>
export default {
    data() {
        return {
            lessonSelection: ''
        };
    },
    mounted() {
        if (this.lessons.length > 0) {
            this.lessonSelection = this.lessons[0];
        }
    },
    watch: {
        lessonSelection: function(value) {
            this.$store.calendar.lesson = value;
        },
        lessons: function(value) {
            if (value.length == 1) {
                this.lessonSelection = value[0];
                return;
            }
            let found = false;
            value.forEach(element => {
                if (element == this.lessonSelection) {
                    found = true;
                }
            });
            if (!found) {
                this.lessonSelection = value[0];
            }
        },
    },
    computed: {
        lessons() {
            return this.$store.calendar.lesson;
        },
        lessons() {
            return this.$store.lessons;
        },
        thisId() {
            return 'select_lesson_' + this._uid;
        }
    },
}
</script>
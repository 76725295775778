import moment from "moment";

class Calendar {
    constructor() {
        this.moment = moment();
        this.moment.locale('en');
        this.currentDay = this.moment.clone();
        this.month = this.moment;
        this.calendar = [];
        this.events = [];
        this.locationsCollected = false;
        this.locations = [];
        this.selectedDay = false;
        this.selectedEvent = false;
        this.todaysEvents = [];
        this.filter = '';
        this.lesson = '';
        this.location = '';
        this.maxPersons = 8;
        this.teacher = '';
        this.hadInitialConsultation = false;
        this.isAlumni = false;
        this.passedInResources = {};
        this.numPeople = 1;
        // this.selectedCoachLocation = false;
        // this.coachLevel = '';
        this.language = 'en';
        this.loading = false;
    }

    setLanguage(lang) {
        this.language = lang;
        this.moment.locale(lang);
    }

    get() {
        let startDay = this.month.clone().startOf('month');
        let endDay = this.month.clone().endOf('month');
        let calendarStart = startDay.clone().startOf('month').day(0);
        let calendarEnd = endDay.clone().endOf('month').day(6);
        let month = [];
        let week = [];

        // Now we have the first Sunday to show, we need to create a "week" array for every week of the month, starting from calendarStart
        while (calendarStart.isBefore(calendarEnd)) {
            // A new week starts every Sunday
            if (calendarStart.day() == 0) {
                // If we already have a full week, add it to the month
                if (week.length > 0) {
                    month.push(week);
                }
                week = [];
            }
            // Push the day info onto the week
            week.push({
                id: calendarStart.format(),
                dayOfWeek: calendarStart.day(),
                inMonth: !(calendarStart.isBefore(startDay) || calendarStart.isAfter(endDay)),
                day: calendarStart.date(),
                isToday: calendarStart.isSame(this.currentDay, 'day'),
                events: [],
            });
            // Add a day
            calendarStart.add(1, 'd');
        }
        // Push the final week onto the month
        month.push(week);

        return month;
    }

    update() {
        this.calendar = this.get();
        this.getEvents();
    }

    getMonthName() {
        return this.month.format('MMM YYYY');
    }

    nextMonth() {
        this.month.add(1, 'months').month();
        this.selectedDay = false;
        this.update();
    }

    previousMonth() {
        if (this.month.isSame(this.currentDay, 'year') && this.month.isSame(this.currentDay, 'month')) {
            return false;
        }
        this.month.subtract(1, 'months').month();
        this.selectedDay = false;
        this.update();
    }

    addEventToCalendar(date, event)
    {
        date = date[0];
        this.calendar.forEach((week, weekIndex) => {
            week.forEach((day, dayIndex) => {
                if (day.id == date) {
                    this.calendar[weekIndex][dayIndex].events.push(event);
                }
            });
        });
    }

    populateTodaysEvents() {
        this.todaysEvents = [];
        this.events.forEach((event, index) => {
            // Check each of the days for the event
            event.days.forEach(date => {
                if (date == this.selectedDay.id) {
                    this.todaysEvents.push(event);
                }
            });
        });
        // Check if our current selected event is part of today's events.
        // Because if it's not, we need to reset it
        let selectedEventStillValid = false;
        this.todaysEvents.forEach(event => {
            if (event.id == this.selectedEvent.id) {
                selectedEventStillValid = true;
            }
        });
        // If we didn't find a valid event, set selected event to the first event if there are any, or false if nothing available for the day
        if (!selectedEventStillValid) {
            this.selectedEvent = (this.todaysEvents.length == 0) ? false : this.todaysEvents[0];
        }
    }

    selectDay(day) {
        this.selectedEvent = false;
        this.selectedDay = day;
        // Set today's events
        this.populateTodaysEvents();

        // Set the first event for the day as selected
        if (this.todaysEvents.length > 0) {
            this.selectedEvent = this.todaysEvents[0];
            this.selectedEvent.selected = true;
        }
        // console.log(this.selectedEvent);
        // this.todaysEvents = [];
        // this.events.forEach((event, index) => {
        //     this.events[index].selected = false;
        //     // Check each of the days for the event
        //     event.days.forEach(date => {
        //         // And if the event date matches today's date, then this event is happening on the date being viewed
        //         // And since the events should be in ascending date order
        //         // This is the first event to select for the day
        //         if (date == day.id) {
        //             this.todaysEvents.push(event);
        //             this.events[index].selected = !firstEvent;
        //             if (firstEvent == false) {
        //                 this.selectedEvent = event.id;
        //             }
        //             firstEvent = true;
        //         }
        //     });
        // });
    }

    selectEvent(selectedEvent) {
        this.selectedEvent = selectedEvent;
        this.events.forEach((event, index) => {
            this.events[index].selected = (event.id == selectedEvent.id) ? true : false;
        });
        this.todaysEvents.forEach((event, index) => {
            this.todaysEvents[index].selected = (event.id == selectedEvent.id) ? true : false;
        });
    }

    minimumFiltersSet() {
        if (this.lesson == '') {
            return false;
        }
        if (this.locationsCollected === false) {
            return false;
        } else if (this.locations.length > 0 && this.location == '') {
            return false;
        }
        // if (this.lesson !== 'Private Foundations Course' && this.location == '') {
        //     return false;
        // }
        return true;
    }

    getEvents() {
        if (!this.minimumFiltersSet()) {
            this.events = [];
            return false;
        }
        // console.log('min filters are fine', this);
        this.loading = true;
        let data = {
            calendar: this.calendar,
            teacher: this.teacher,
            lesson: this.lesson,
            location: this.location,
            hadInitialConsultation: this.hadInitialConsultation,
            isAlumni: this.isAlumni,
            passedInResources: this.passedInResources,
            numPeople: this.numPeople,
            lang: this.language,
        };

        axios.post(process.env.MIX_APP_URL + 'events?t=' + Date.now(), data)
            .then((response) => {
                this.events = [];
                let firstEvent = true;

                // Iterate over the days in the response
                response.data.forEach(event => {
                    event.selected = firstEvent;
                    firstEvent = false;
                    this.events.push(event);
                });
                this.loading = false;
                this.selectFirstEvent();
                // this.populateTodaysEvents();
            })
            .catch(error => {
                console.error("resources/js/classes/Calendar.js@getEvents()", error);
                this.loading = false;
            });
    }

    setLesson(lesson) {
        this.lesson = lesson;
    }

    setLocation(location = '') {
        if (location.prop_location) {
            this.location = location.prop_location || '';
            this.maxPersons = location.num_persons || 8;
            return;
        }
        this.location = '';
        this.maxPersons = 8;
        this.selectedEvent = false;
    }

    getLocations() {
        return new Promise((resolve, reject) => {
            if (this.lesson == '') {
                this.locations = [];
                resolve([]);
                return;
            }
            this.locationsCollected = false;
            axios.post(process.env.MIX_APP_URL + 'locations', {
                lang: this.language,
                teacher: this.teacher,
                lesson: this.lesson,
                hadInitialConsultation: this.hadInitialConsultation,
                isAlumni: this.isAlumni,
                passedInResources: this.passedInResources,
            })
            .then(response => {
                this.locations = response.data;
                this.locationsCollected = true;
                // If there's only 1 location, set that as the selection
                if (this.locations.length == 1) {
                    this.setLocation(this.locations[0]);
                    // console.log(4);
                    // this.getEvents();
                }
                resolve(this.locations);
            })
            .catch(err => {
                this.locations = [];
                this.locationsCollected = true;
                console.error('Calendar@getLocations error', err);
                resolve(this.locations);
            });
        });
    }

    setTeacher(teacher) {
        this.teacher = teacher;
    }

    // setCoachLevel(coachLevel) {
    //     this.coachLevel = coachLevel;
    // }

    setFilter(filter) {
        this.filter = filter;
    }

    selectFirstEvent() {
        if (this.events.length == 0) {
            return;
        }
        const day = this.getDayEventIsOn(this.events[0]);
        this.selectDay(day);
    }

    getDayEventIsOn(event) {
        let dayEventIsOn = false;
        this.calendar.forEach(week => {
            if (dayEventIsOn !== false) {
                return;
            }
            week.forEach(day => {
                if (dayEventIsOn !== false) {
                    return;
                }
                if (day.id == event.days[0]) {
                    dayEventIsOn = day;
                }
            });
        });
        return dayEventIsOn;
    }

}

export default Calendar;
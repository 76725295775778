
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

const Vue = require('vue');
import VueStash from 'vue-stash';
import Vuebar from 'vuebar';
import lineClamp from 'vue-line-clamp';
import VueScrollTo from 'vue-scrollto';
import Calendar from './classes/Calendar';

let calendar = new Calendar();
Vue.use(VueScrollTo);
Vue.use(VueStash);
Vue.use(Vuebar);
Vue.use(lineClamp, {});
Vue.component('availability-calendar', require('./components/AvailabilityCalendar/AvailabilityCalendar.vue').default);

Vue.component('event-calendar-week', require('./components/EventCalendarWeek.vue').default);
Vue.component('event-calendar-day', require('./components/EventCalendarDay.vue').default);

Vue.component('event-detail', require('./components/EventDetail.vue').default);

const app = new Vue({
    el: '#app',
    data: {
        store: {
            calendar: calendar,
            lessons: [],
            teacher: '',
            filter: '',
            language: '',
            locations: [],
            selectedTeacherLocation: '',
            resources: {},
            hadInitialConsultation: false,
            isAlumni: false,
            passedInResources: {},
            user: false,
            foundationsCourse: false,
        },
        filter: '',
        language: 'en',
    },
    watch: {
        language(val, oldVal) {
            this.$store.language = val;
            this.$store.calendar.setLanguage(val);
            this.$store.calendar.update();
        },
        filter(val, oldVal) {
            this.$store.filter = val;
            this.$store.calendar.setFilter(val);
            this.$store.calendar.getEvents();
        },
        'store.calendar.locations': function(val, oldVal) {
            // Check that the selected location is still available
            if (val.length == 0) {
                this.$store.calendar.setLocation('');
                return;
            }
            let foundLocation = false;
            this.$store.calendar.locations.forEach(element => {
                if (foundLocation) {
                    return;
                }
                if (typeof element.prop_location !== 'undefined' && element.prop_location == this.$store.calendar.location) {
                    foundLocation = true;
                }
            });
            if (!foundLocation) {
                this.$store.calendar.setLocation('');
            }
        },
        'store.calendar.location': function(val, oldVal) {
            if (val == '') {
                this.$store.calendar.selectedEvent = false;
            }
            // When the location changes, we just get a string for the location name, so we need to go through each location and check if the string name matches.
            // If it does, we need to use the num_persons value for that location to restrict the num_persons drop down
            // Also need to reset the value if the new max is less than the old selected value
            this.$store.calendar.locations.forEach(location => {
                if (location.prop_location && val == location.prop_location) {
                    this.$store.calendar.maxPersons = location.num_persons || 8;
                    if (this.$store.calendar.maxPersons < this.$store.calendar.numPeople) {
                        this.$store.calendar.numPeople = this.$store.calendar.maxPersons;
                    }
                }
            })

            if (val !== oldVal) {
                this.$store.calendar.getEvents();
            }
        },
        'store.calendar.numPeople': function(val, oldVal) {
            if (val !== oldVal) {
                this.$store.calendar.getEvents();
            }
        },
        'store.calendar.lesson': function(val, oldVal) {
            // If the lesson is changed to blank, blank the location too
            if (val == '') {
                this.$store.calendar.setLocation('');
            }
            // If the value has changed, then collect the new list of locations from the server
            if (val !== oldVal) {
                this.$store.calendar.getLocations()
                .then(() => {
                    this.$store.calendar.getEvents();
                });
            }
        },
        'store.hadInitialConsultation': function(val, oldVal) {
            this.$store.calendar.hadInitialConsultation = val;
        },
        'store.isAlumni': function(val, oldVal) {
            this.$store.calendar.isAlumni = val;
        },
        'store.passedInResources': function(val, oldVal) {
            this.$store.calendar.passedInResources = val;
        },
        // 'store.selectedCoachType': function(val, oldVal) {
        //     this.$store.calendar.setCoachLevel(val);
        //     this.$store.calendar.update();
        // },
    },
    created() {
        this.$store.language = this.language;
        this.$store.filter = this.filter;
        this.$store.calendar.setLanguage(this.language);
        this.$store.calendar.setFilter(this.filter);
        this.$store.calendar.update();
    },
});

<template>
<div class="form-group gokhale-cal__inputs" v-if="locations.length > 1">
    <label :for="thisId">Select a location</label>
    <select :id="thisId" v-model="location" class="form-control">
        <option value="">Select a location</option>
        <option v-for="(l, index) in locations" :key="index" :value="l.prop_location">{{ l.prop_location }}</option>
    </select>
</div>
</template>

<script>
export default {
    store: {
        locations() {
            return 'calendar.locations';
        },
        location() {
            return 'calendar.location';
        },
    },
    computed: {
        thisId() {
            return 'select_location_' + this._uid;
        }
    },
}
</script>
<template>
    <div class="planyo-event-list">
        <h2>Upcoming Events</h2>
        <div v-bar class="scroll-area">
            <div>
                <div class="no-events" v-if="noEvents" v-html="reasonForNoEvents"></div>
                <div v-else v-for="event in todaysEvents" :key="event.id" :class="{ 'event':true , 'event-selected': (event.id == selectedEvent.id) }" @click="selectEvent(event)" :id="'event-' + event.id">
                    <h3>{{ startTime(event) }}</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        store: {
            todaysEvents() {
                return 'calendar.todaysEvents';
            },
            selectedEvent() {
                return 'calendar.selectedEvent';
            },
        },
        watch: {
            selectedEvent: function(newVal, oldVal) {
                this.$store.selectedTeacherLocation = newVal.planyo_id;
            },
        },
        computed: {
            reasonForNoEvents() {
                if (this.$store.calendar.lesson == '' || this.$store.calendar.location == '') {
                    if (this.$store.calendar.lesson == '' && this.$store.calendar.location == '') {
                        return 'Please select a lesson and location to view upcoming availabilty';
                    } else if (this.$store.calendar.lesson == '') {
                        return 'Please select a lesson to view upcoming availabilty';
                    }
                    return 'Please select a location to view upcoming availabilty';
                }
                if (this.$store.calendar.selectedDay === false && this.$store.calendar.todaysEvents.length == 0) {
                    return 'Please select a day of the month to view upcoming availability';
                }
                if (this.haveEventsThisMonth && this.$store.calendar.todaysEvents.length == 0) {
                    return 'There are no lessons scheduled on this day, please select a different day';
                }
                if (!this.haveEventsThisMonth) {
                    return 'There are no lessons scheduled this month, please check other months';
                }
                return '';
            },
            haveEventsThisMonth() {
                return (this.$store.calendar.events.length > 0) ? true : false;
            },
            noEvents() {
                if (this.haveEventsThisMonth) {
                    if (this.$store.calendar.todaysEvents.length == 0) {
                        return true;
                    }
                    return false;
                }
                return true;
            },
        },
        methods: {
            startTime(event) {
                let time = moment(event.start_time.date);
                return time.format("h:mm a");
            },
            selectEvent(selectedEvent) {
                this.$store.calendar.selectEvent(selectedEvent);
                this.$store.selectedTeacherLocation = selectedEvent.planyo_id;
            }
        },
    }
</script>
